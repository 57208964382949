import CustomState from '@rio-cloud/rio-uikit/lib/es/CustomState';
import { FormattedMessage } from 'react-intl';

const FeedbackReceived = () => (
    <CustomState
        headline={
            <span className="text-bold text-color-darkest">
                <FormattedMessage id={'feedbackcare.info.feedback.reply_header'} />
            </span>
        }
        message={<FormattedMessage id={'feedbackcare.info.feedback.reply_content'} />}
        icons={[
            {
                name: 'rioglyph rioglyph-ok-sign',
                className: 'text-size-400pct',
                color: 'text-color-highlight',
            },
        ]}
        outerClassName="border-none"
    />
);

export default FeedbackReceived;
